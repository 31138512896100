import './Home.css'

function Home() {
    return (
        <div className="Home-div">
      <header className="Home-header">
        <h2>
          Jess Testa
        </h2>
      </header>
    </div>
    )
}

export default Home;
